<template >

  <div class="alt-product-toolbar">
    <div class="btn-con">
      <f7-button class="serv-btn">
          <img :src="require('@/assets/images/con-ser-icon.png')" style="width: 45px; position: relative; top: 10px;" loading="lazy" />
          <p class="serv-text" style="margin:0px; bottom: 5px; position: relative;">Customer Service</p>
      </f7-button>

      <f7-button class="cart-btn">
          <font-awesome-icon  style="font-size: 20px;" :icon="['far', 'shopping-cart']" fixed-width />
          <p class="serv-text" style="margin:0px; position: relative; color: #111; font-size: 9px;">Cart</p>
      </f7-button>
      <div class="add-btn-con" style="display:flex">
      <f7-button  class="add-to-cart-btn">
        <p>Add to Cart</p>
      </f7-button>
      <f7-button  class="buy-now-btn">
        <p>Buy Now</p>
      </f7-button>
      </div>
      </div>
   </div>

</template>

<script>
import { defineComponent, ref, inject, computed, defineAsyncComponent } from 'vue'
import dayjs from 'dayjs'
import { helpers } from '@/utils/helpers.js'
import { post } from '@/utils/axios'
import { useStore } from '@/store'
import { f7 } from "framework7-vue";



export default defineComponent({
  name: 'ProductToolbarComponent',
  components: {
   },
  props: { kol: String, postkey: String, product: Object, f7route: Object },
  setup(props) {
    const store = useStore();
   
    const $t = inject('$translation')
    const $f7router = f7.views.get("#main-view").router;
    

    return {
      store
    }
  }
})
</script>

<style scoped>
.alt-product-toolbar {
    bottom: 75px;
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 5;
    background-color: #fff;
    padding: 10px 0px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.btn-con {
    display: flex;
    width: 100%;
    justify-content: center;
}
.md .toolbar-inner .alt-product-toolbar {
    text-align: center;
    overflow: visible;
    display: flex;
    justify-content: center;
}
.add-to-cart-btn {
  width: 95px;
  height: 60px;
  padding: 0px;
  border-radius: 0px;
  background-color: #1a64ca;
  margin-left: auto;
  color: #fff;
  border-radius: 15px 0px 0px 15px;
  font-size: 11px;
  text-transform: none;
}
.buy-now-btn {
  width: 95px;
  height: 60px;
  padding: 0px;
  border-radius: 0px;
  background-color: #0C4D8F;
  margin-left: auto;
  color: #fff;
  border-radius: 0px 15px 15px 0px;
  font-size: 11px;
  text-transform: none;
  
}
.serv-btn {
  width: 120px;
  height: 60px;
  padding: 0px;
  border-radius: 0px;
  background-color: #fff;
  color: #111;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  font-size: 9px;
  font-weight: 600;
  text-transform: none;
}
.cart-btn {
  width: 60px;
  height: 60px;
  padding: 0px;
  border-radius: 0px;
  background-color: #fff;
  margin-left: auto;
  color: #1f61ca;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
  padding-top: 15px;
  text-transform: none;
}

</style>
